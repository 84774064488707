.react-player {
    border-radius: 0.25rem;
    position: relative;

    .dropdown-menu {
        min-width: 0 !important;
        box-shadow: none !important;
        padding: 0 !important;
        border-radius: 0 !important;

        &.show {
            inset: auto auto 2px 0px !important;
            margin-left: 3px !important;
            background-color: rgba(43, 51, 63, 0.7);
            transform: translate(0px, -39px) !important;
        }
    }

    .dropdown-item {
        padding: 0 !important;
        color: #fff !important;
        text-shadow: 0em 0em 1em #FFFFFF, 0em 0em 0.5em #FFFFFF;

        &:hover {
            background-color: rgba(115, 133, 159, 0.5) !important;
            color: #fff !important;
            border-radius: 0 !important;
            text-shadow: 0em 0em 1em #ffffff, 0em 0em 0.5em #ffffff;
        }

        &:focus {
            background-color: #1E242C !important;
        }

        &.active,
        &:active {
            text-decoration: none;
            background-color: #fff !important;
            color: #333333 !important;
            border-radius: 0;
        }
    }

    .dropdown-toggle,
    .btn,
    .btn-secondary {
        font-size: 10px !important;

        &:focus {
            text-shadow: 0em 0em 1em #ffffff, 0em 0em 0.5em #ffffff;
        }
    }

    .dropdown-toggle::after {
        border-bottom: none !important;
    }

    .btn-sm {
        padding: 0.2em 0.4em !important;
        line-height: 1.4em;
        font-size: 1.2em;
    }

    .btn {
        border: none !important;
        border-radius: 0 !important;

        &:focus {
            box-shadow: none !important;
        }
    }

    .btn-secondary {
        background-color: transparent !important;

        &:hover {
            color: #fff;
            background-color: transparent !important;
            border-color: #545b62;
        }

        &:focus {
            color: #fff;
            background-color: transparent;
            border-color: #2E2A3C;
            border: none !important;
            box-shadow: none !important;
        }
    }
}

.video-react {

    .video-react-poster {
        background-size: cover !important;
    }
}
//.video-react .video-react-video{
//    max-height: calc(100vh - 50px);
//}

.video-react.video-react-fluid{
    padding-top: 56.25% !important;
    max-height: calc(100vh - 150px) !important;
    height: calc(100vh - 150px) !important;
}
@media (min-width: 1400px) {
    .video-react.video-react-fluid{
        max-height: calc(100vh - 200px) !important;
        height: calc(100vh - 200px) !important;
    }
}
@media (min-width: 1600px) {
    .video-react.video-react-fluid{
        max-height: calc(100vh - 400px) !important;
        height: calc(100vh - 400px) !important;
    }
}

.double-click-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    font-size: 20px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #000000;
    color: white;
    transition: opacity 0.5s ease-in-out;
    cursor: no-drop;
    pointer-events: none;

    i {
        font-size: 35px;
    }
}

.double-click-icon.show {
    opacity: 1;
}

.video-react-control-bar {
    white-space: nowrap;
    //overflow-x: auto;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.video-react .video-react-control-bar {
    background-color: #090d1924 !important;
}

.react-player {
    .video-react-lock-showing {
        .video-react-menu-content {
            li.video-react-menu-item {
                font-family: "proxima_novaregular", serif !important;
            }
        }
    }

    .video-react .video-react-loading-spinner {
        border: 2px solid rgba(43, 51, 63, 0.7);

    }

    .video-react .video-react-loading-spinner:before,
    .video-react .video-react-loading-spinner:after {
        margin: -2px;
    }

    .custom-resolution-control-drop.dropup.btn-group button.dropdown-toggle.btn.btn-secondary {
        font-size: 12px !important;
        font-family: "proxima_novaregular", serif;
    }

    .video-react-current-time-display,
    .video-react-time-divider,
    .video-react-duration-display {
        font-family: "proxima_novaregular", serif;
    }

    .video-react-time-control,
    .video-react-playback-rate,
    .dropup,
    .video-react-menu,
    .dropdown-item,
    .video-react-lock-showing {
        font-family: "proxima_novaregular", serif !important;
        text-align: center;
    }
    
    .custom-unmute-btn{
        position: absolute;
        z-index: 1;
        top: 20px;
        right: 20px;
        cursor: pointer;
        font-size: 18px;
        color: #fff;
        background-color: #000;
        padding: 9px 20px;
        border-radius: 40px;
        animation: fadeIn 1s ease-in-out;

        @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
        }
        
        span{
            margin-right: 15px;
        }
        
        img {
            width: 35px;
        }
    }

    .custom-play-pause-center {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: rgba(43, 51, 63, 0.7);
        color: white;
        opacity: 0;
        transition: all 0.1s;
        cursor: pointer;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;

        &.pause {
            &:before {
                font-size: 1.8em;
                line-height: 1.67;
                content: "\f203";
                position: absolute;
                font-size: 4em;
                line-height: 1.5em;
                text-align: center;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-family: "video-react" !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                -webkit-font-smoothing: antialiased;
            }
        }

        &.play {
            &:before {
                content: "\f200";
                position: absolute;
                font-size: 4em;
                line-height: 1.5em;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                height: 100%;
                font-family: "video-react";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                -webkit-font-smoothing: antialiased;
            }
        }

        i {
            font-size: 40px;
        }

        &.show {
            opacity: 1;
        }
    }
    
    .video-react-menu-content{
        display: flex;
        align-items: center;
    }

    @media (min-width: 576px) {
        .video-react {

            .video-react-volume-bar.video-react-slider-horizontal {
                width: 5em;
                height: 0.5em;
            }
            .video-react-volume-level:before {
                font-size: 1.3em;
            }
            .video-react-volume-bar {
                margin: 1.32em 0.45em;
            }
            .video-react-slider-horizontal .video-react-volume-level:before {
                top: -0.32em;
            }
            .video-react-slider-horizontal .video-react-volume-level {
                height: 0.5em;
            }

            .video-react-progress-control .video-react-progress-holder {
                height: 0.5em !important;
            }

            .video-react-control-bar {
                font-size: 14px;
            }

            .video-react-load-progress {
                height: 0.5em !important;
            }

            .video-react-play-progress.video-react-slider-bar {
                height: 0.5em !important;
            }

            .video-react-play-progress:before {
                position: absolute;
                top: -0.201em !important;
                right: -0.5em;
                font-size: 0.9em;
            }

            .video-react-progress-holder .video-react-load-progress div {
                height: 0.5em !important;
            }
        }

        .video-react-progress-control.video-react-control:hover {
            .video-react-play-progress:before {
                position: absolute;
                top: -0.225em !important;
                right: -0.5em;
                font-size: 0.9em;
            }
        }

        .custom-resolution-control-drop.dropup.btn-group button.dropdown-toggle.btn.btn-secondary {
            font-size: 14px !important;
        }
    }

    @media (max-width: 576px) {
        .video-react-fullscreen {
            .video-react-control-bar {
                margin-bottom: 10px;
            }
        }

        .video-react .video-react-menu-button-popup .video-react-menu .video-react-menu-content {
            z-index: 999999999999999;
            position: relative !important;
        }

        .video-react-volume-bar {
            z-index: 10; /* Example adjustment */
        }
        .video-react .video-react-menu-button-popup.video-react-volume-menu-button-vertical .video-react-menu {
            z-index: 999;
        }
        .double-click-icon {
            z-index: 9;
        }

        .video-react {
            font-size: 13px;

            .video-react-playback-rate {
                display: none;
            }

            .video-react-time-control {
                padding-left: 5px !important;
                padding-right: 5px !important;
            }

            .dropup .dropdown-toggle::after {
                display: none !important;
            }

            .dropdown-menu.show {
                transform: translate(0px, -36px) !important;
            }

            .dropdown-item {
                line-height: 1em;

                small {
                    font-size: 14px !important;
                }
            }
        }
        
        .custom-unmute-btn {
            top: 10px;
            right: 10px;
            font-size: 15px;
            border-radius: 40px;
            
            img {
                width: 20px;
            }
        }

        .custom-play-pause-center {
            width: 75px;
            height: 75px;

            &.pause {
                &:before {
                    font-size: 3em;
                    line-height: 1.7em;
                }
            }

            &.play {
                &:before {
                    font-size: 3em;
                    line-height: 1.7em;
                }
            }
        }
    }

}

.video-react .video-react-big-play-button {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    border: none !important;
    background-color: transparent;
    font-size: 6em !important;
    line-height: 1.5em !important;
    height: 1.5em !important;
    width: 1.5em !important;
    border-radius: 50% !important;
}

.video-react:hover .video-react-big-play-button,
.video-react .video-react-big-play-button:focus,
.video-react .video-react-big-play-button:hover {
    background-color: transparent !important;
    border: none !important;
    transition: 0.3s ease;
    font-size: 7em;
}

.video-react .video-react-loading-spinner {
    border: 2px solid #4F4F4F;
}

.video-react .video-react-loading-spinner::before,
.video-react .video-react-loading-spinner::after {
    // margin: -2px !important;
}

.video-react-time-control,
.video-react-playback-rate,
.dropup,
.video-react-menu,
.react-player .dropdown-item {
    font-family: "proxima_novaregular" !important;
}

.video-react .video-react-control:focus::before,
.video-react .video-react-control:hover::before,
.video-react .video-react-control:focus,
.react-player .dropdown-item,
.react-player .btn-secondary:focus {
    text-shadow: none !important;
}

.custom-resolution-control-main {
    .custom-resolution-control-main-inner {
        height: 100% !important;

        .custom-resolution-control-drop {
            height: 100% !important;
        }
    }
}

.video-react .video-react-slider:focus {
    box-shadow: none;
}

.video-react video:focus {
    outline: none;
    /* Remove the default focus border */
}


@media (max-width: 991px) {
    .double-click-icon {
        width: 70px;
        height: 70px;

        i {
            font-size: 25px;
        }
    }
}

@media (max-width: 450px) {
    .video-react .video-react-control {
        width: 2em;
    }

    .double-click-icon {
        width: 50px;
        height: 50px;

        i {
            font-size: 22px;
        }
    }
}