.double-confirmation {    
    .modal-content {
        max-width: 380px;
        margin: auto;

        .modal-header {
            border: 0;
            display: none;
        }

        .modal-body {
            text-align: center;
            padding: 20px 50px;
            padding-bottom: 35px;

            .delete-icon {
                width: 110px;
                border-radius: 100%;
                margin: 10px auto;
                margin-bottom: 15px;
                height: 110px;
                padding: 20px;
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #FCF1F1;

                svg {
                    width: 50px;
                    height: 50px;
                    color: #FF5252;
                }
            }

            h3 {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 5px;
            }

            p {
                margin-bottom: 0;
            }
        }

        .modal-bottom {
            border: 0;
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 25px;
            .button-1 {
                width: 47%;

                &:after {
                    display: none;
                }
            }

            .confirm-btn {
                background-color: #FF5252;

                &:hover {
                    background-color: #c23a3a;
                }
                &:disabled {
                    background-color: #FF5252;
                    cursor: not-allowed;
                }
            }
        }
    }

}
@media (max-width: 400px) {
    .double-confirmation {
        .modal-content {
            .modal-body {
            padding: 20px 40px;
            }
        }
    }
}