.main-wrap {
    width: calc(100% - 230px);
    position: relative;
    // top: 65px;
    padding-top: 40px;
    overflow: hidden;
    float: right;
    min-height: calc(100vh - 65px);
    border-radius: 2px 0px 0px 0px;
}

@media (max-width: 991px) {
    .main-wrap{
        width: 100%;
        margin-bottom: 100px;
    }
}