.footer{
    display: flex;
    justify-content: center;
    background-color: #F0F0F0;
    padding: 20px 0;
    margin-top: 30px;
    .button-1{
        padding: 0px;
        font-size: 15px;
        color: var(--text-color);
        background-color: transparent;
        &:hover{
            background-color: transparent;
            color: var(--primary-color);
        }
    }
}