.conversion-modal {
    .modal-content {
        .modal-header {
            border: none;
            position: relative;
            display: inline;
            padding: 0;

            .modal-title {
                display: none;
            }

            .modal-close-b {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 99;
            }
        }

        .modal-body {
            padding: 20px;
            padding-top: 40px;
            text-align: center;

            p {
                font-size: 16px;
                color: #000;

                span {
                    cursor: pointer;
                    font-weight: 500;
                    color: var(--primary-color);
                }
            }
        }
    }
}