.feedback-modal {
    .modal-content {
        .modal-header {
            display: none;
        }

        .modal-body {
            padding: 25px 20px;
            .feedback-modal-top {
                text-align: center;
                
                h3 {
                    font-size: 20px;
                    margin-bottom: 15px;
                    color: var(--text-color);
                }

                p {
                    padding: 0px 15px;
                    font-size: 16px;
                    line-height: 22.69px;
                    color: var(--text-color);
                }
            }

            .start-rating{
                text-align: center;
                margin: 20px 0px;
            }

            .upload-video-btn {
                display: flex;
                gap: 10px;
                justify-content: flex-end;
              }
        }
    }
}