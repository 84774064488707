.cropper-main-container {
    padding: 10px 10px;
    height: 400px;
    width: 100%;
    position: relative;
    background-color: rgba(242, 242, 242, 0.7);
}

.container-cropper {
    height: 90%;
    padding: 0;
}

.cropper {
    height: 270px;
    position: relative;
}


.container-cropper .slider {
    padding: 12px 0px;
    max-width: 300px;
    margin: 0 auto;
}

.container-cropper .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    background: #3f51b5;
    width: 100%;
}

.container-cropper .zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.container-cropper .zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.container-cropper .controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
}

.container-cropper .controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.container-cropper .container-buttons {
    border: 1px solid #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.container-cropper .action-block{
    margin-top: 0 !important;
    display: flex;
    justify-content: space-between;
    max-width: 450px;
    margin: 0 auto;
}

.container-cropper .action-block .form-submit, .container-cropper .action-block .form-cancel {
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
}
.container-cropper .action-block button{
    width: 100%;
    padding: 16px 10px;
    border-radius: 8px;
}
.container-cropper .action-block .form-submit.choose{
    margin: 0px 15px;
    background: var(--headding-color);
}
.container-cropper .action-block .form-cancel{
    color: var(--headding-color);
    background: #ddd;
}