.user-side-wrap{
    .sidebar {
        display: none;
    }
    .mobile-sticky-menu{
        display: none;
    }
    .header{
        .menu {
            ul {
                li {    
                    .mobile-number {
                        display: inline-block;
                    }
                    .logout-btn {
                       display: none;
                    }
                }
            }
        }
    }
}