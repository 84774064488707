.pagination-component-wrapper {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    ul {
        text-align: center;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-flex;
            width: 30px;
            height: 30px;
            border: 1px solid #ddd6;
            align-items: center;
            justify-content: center;
            line-height: 25px;
            font-size: 14px;
            cursor: pointer;
            margin: 0px 4px;
            background: #fff;
            font-weight: 600;
            border-radius: 2px;
            transition: all .3s ease;
            &.selected{
                border-color: var(--primary-color);
                background: var(--primary-color);
                color: #fff;
            }
            &.disabled{
                cursor: no-drop;
                pointer-events: none;
            }
            &:hover{
                background: #ddd6;
                &.selected{
                    border-color: var(--primary-color);
                    background: var(--primary-color);
                    color: #fff;
                }
            }
            i{
                line-height: 1;
                font-weight: 700;
            }
        }
    }
}
@media (max-width: 767px) {
    .pagination-component-wrapper {
        padding: 0px;
    }
}