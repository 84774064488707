@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
$fontRobotSlab: "IBM Plex Sans", sans-serif;
$fontRoboto: 'proxima_novaregular';

:root {
    --body-color: #747674;
    --primary-color: #40C1AE;
    --headding-color: #02203E;
    --section-bg: #FAFAFC;
    --white-bg: #fff;
    --text-color: #282828;
    --border-color: #646464;
    --btn-bg: #ECF9F9;
}

body {
    margin: 0;
    padding: 0;
    font-size: 15px;
    color: var(--body-color);
    font-family: $fontRoboto;
    font-weight: normal;
    font-style: normal;
}

a,
button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

*::-moz-selection {
    background: var(--primary-color);
    color: #fff;
    text-shadow: none;
}

::-moz-selection {
    background: var(--primary-color);
    color: #fff;
    text-shadow: none;
}

::selection {
    background: var(--primary-color);
    color: #fff;
    text-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--text-color);
    font-weight: 700;
    font-family: $fontRobotSlab;
}

a {
    text-decoration: none;
    transition: .4s;
    -webkit-transition: all .4s ease-in-out;
}

a:hover {
    text-decoration: none;
    color: var(--primary-color);
}

button:focus {
    outline: none;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

p {
    color: var(--body-color);
    line-height: 26px;
}

img {
    max-width: 100%;
}

.section-bg {
    background-color: var(--section-bg);
}

.section-padding {
    padding: 100px 0px;
}

.section-padding-2 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.section-padding-80 {
    padding: 80px 0px;
}

.section-padding-70 {
    padding: 70px 0px;
}

.section-padding-50 {
    padding: 50px 0px;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}


.dropdown-menus {
    background: #fff none repeat scroll 0 0;
    right: 0px;
    line-height: normal;
    opacity: 0;
    text-align: left;
    top: 100%;
    transition: all .2s linear 0s;
    visibility: hidden;
    position: absolute;
    border-radius: 4px;
    width: 130px;
    z-index: 1;
    padding: 3px 5px;
    border: 1px solid #CCD2D8;
    box-shadow: 4px 11px 58px 0px #00000040;
    top: 45px;

    &:after {
        content: "";
        position: absolute;
        top: -20px;
        right: 10px;
        border: 10px solid transparent;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom-color: #fff;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }

    ul.menu-ul {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        overflow: hidden;
        margin-top: 4px;
        padding-bottom: 0px !important;

        .menu {
            button {
                background: #fff;
                display: inline-block;
                align-items: center;
                border: 0;
                color: var(--text-color);
                font-size: 15px;
                letter-spacing: 0.28px;
                padding: 4px 6px;
                position: relative;
                text-transform: capitalize;
                width: 100%;
                text-align: left;

                svg {
                    display: inline-block;
                    height: 15px;
                    width: 15px;
                    margin-right: 10px;
                    margin-bottom: 5px;
                }
            }

            &:hover {
                background: transparent;

                button {
                    background: transparent;
                    color: var(--primary-color);
                }
            }
        }
    }
}


/* Padding Top */
.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-35 {
    padding-top: 35px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-55 {
    padding-top: 55px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-65 {
    padding-top: 65px;
}

.pt-70 {
    padding-top: 70px;
}

.pt-75 {
    padding-top: 75px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-85 {
    padding-top: 85px;
}

.pt-90 {
    padding-top: 90px;
}

.pt-95 {
    padding-top: 95px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-105 {
    padding-top: 105px;
}

.pt-110 {
    padding-top: 110px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-130 {
    padding-top: 130px;
}

.pt-140 {
    padding-top: 140px;
}

.pt-150 {
    padding-top: 150px;
}

.pt-160 {
    padding-top: 160px;
}

.pt-170 {
    padding-top: 170px;
}

.pt-180 {
    padding-top: 180px;
}

.pt-190 {
    padding-top: 190px;
}

.pt-200 {
    padding-top: 200px;
}


/* Padding Bottom */
.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-35 {
    padding-bottom: 35px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-45 {
    padding-bottom: 45px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-55 {
    padding-bottom: 55px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-65 {
    padding-bottom: 65px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-75 {
    padding-bottom: 75px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-85 {
    padding-bottom: 85px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pb-95 {
    padding-bottom: 95px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-130 {
    padding-bottom: 130px;
}

.pb-140 {
    padding-bottom: 140px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pb-160 {
    padding-bottom: 160px;
}

.pb-170 {
    padding-bottom: 170px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pb-190 {
    padding-bottom: 190px;
}

.pb-200 {
    padding-bottom: 200px;
}


/* margin Top */
.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-65 {
    margin-top: 65px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-75 {
    margin-top: 75px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-85 {
    margin-top: 85px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-95 {
    margin-top: 95px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-110 {
    margin-top: 110px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-130 {
    margin-top: 130px;
}

.mt-140 {
    margin-top: 140px;
}

.mt-150 {
    margin-top: 150px;
}

.mt-160 {
    margin-top: 160px;
}

.mt-170 {
    margin-top: 170px;
}

.mt-180 {
    margin-top: 180px;
}

.mt-190 {
    margin-top: 190px;
}

.mt-200 {
    margin-top: 200px;
}


/* margin bottom */
.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-85 {
    margin-bottom: 85px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-95 {
    margin-bottom: 95px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-110 {
    margin-bottom: 110px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-130 {
    margin-bottom: 130px;
}

.mb-140 {
    margin-bottom: 140px;
}

.mb-150 {
    margin-bottom: 150px;
}

.mb-160 {
    margin-bottom: 160px;
}

.mb-170 {
    margin-bottom: 170px;
}

.mb-180 {
    margin-bottom: 180px;
}

.mb-190 {
    margin-bottom: 190px;
}

.mb-200 {
    margin-bottom: 200px;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.two-line-clamp {
    display: -webkit-box;
    /* Required for webkit-based browsers */
    -webkit-box-orient: vertical;
    /* Required for webkit-based browsers */
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    overflow: hidden;
    /* Ensures content is hidden after the specified lines */
    text-overflow: ellipsis;
    /* Optional: adds ellipsis after the truncated text */
}

.one-line-clamp {
    display: -webkit-box;
    /* Required for webkit-based browsers */
    -webkit-box-orient: vertical;
    /* Required for webkit-based browsers */
    -webkit-line-clamp: 1;
    /* Number of lines to show */
    overflow: hidden;
    /* Ensures content is hidden after the specified lines */
    text-overflow: ellipsis;
    /* Optional: adds ellipsis after the truncated text */
}

.no-video-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 255px);

    span{
        font-size: 20px;
        color: var(--text-color);
        font-weight: 500;
    }
}

@media (max-width: 991px) {
    .dropdown-menus {
        top: 40px;
        right: -4px;
    }
}