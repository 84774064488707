.video-upload-control {
    box-shadow: 4px 11px 58px 0px #00000012;
    padding: 20px 15px;
    background: var(--white-bg);
    margin-top: -88px;
    transition: all 0.3s;
    display: flex;
    position: fixed;
    top: 152px;
    width: calc(100% - 230px);
    z-index: 99;

    &.active {
        margin-top: -1px;
    }

    .video-upload-left {
        display: flex;
        width: 83%;
        float: left;

        .search-option {
            width: 50%;
            margin-right: 15px;
        }

        .upload-buttons {
            display: flex;
            gap: 15px;

            &.active{
                pointer-events: none;
                opacity: 0.5;
                background-color: var(--white-bg);
                cursor: pointer;
            }

            .btn-outline {
                border: 1px solid var(--primary-color);
                padding: 8px 13px;
                color: var(--primary-color);
                background: transparent;

                &:hover {
                    background: #f7f7f7;
                }
            }

            .btn-upload {
                background: var(--primary-color);
                padding: 8px 13px;
                color: var(--white-bg);
                border: 1px solid var(--primary-color);

                &:hover {
                    border: 1px solid #33a594;
                    background: #33a594;
                }
            }
        }
    }

    .card-format {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        width: 17%;

        &.active{
            pointer-events: none;
            opacity: 0.5;
            background-color: var(--white-bg);
            cursor: pointer;
        }

        .button-1 {
            border: 1px solid var(--border-color);
            color: var(--primary-color);
            background: transparent;
            width: 47px;
            height: 47px;

            &:hover {
                background: #f7f7f7;
            }

            .icon-1 {
                margin-right: 0px;
                svg {
                    width: 28px;
                    height: 28px;
                    color: #000000;
                }
            }
        }

        .active-btn {
            background: var(--btn-bg);
            border-color: var(--primary-color);
            &:hover{
                background-color: #e1fafa;
            }
            .icon-1 {
                svg {
                    color: var(--primary-color);
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .video-upload-control {

        .video-upload-left {
            .upload-buttons {
                .button-1{
                    padding: 8px 10px;
                    font-size: 15px;
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .video-upload-control {

        .video-upload-left {
            .search-option {
                width: 45%;
                margin-right: 10px;
            }

            .upload-buttons {
                // display: none;
                gap: 10px;
                .button-1{
                    padding: 8px 10px;
                    font-size: 15px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .video-upload-control {
        box-shadow: 4px 11px 58px 0px #00000012;
        padding: 20px 15px;
        background: var(--white-bg);
        margin-top: -88px;
        transition: all 0.3s;
        width: 100%;

        &.active {
            margin-top: 0px;
        }

        .video-upload-left {
            display: flex;

            // margin-right: 10px;
            .search-option {
                width: 95%;
                margin-right: 0px;
            }

            .upload-buttons {
                display: none;
            }
        }
    }
}

@media (max-width: 576px) {
    .video-upload-control {
        box-shadow: 4px 11px 58px 0px #00000012;
        padding: 20px 10px;
        background: var(--white-bg);
        margin-top: -88px;
        transition: all 0.3s;

        &.active {
            margin-top: 0px;
        }

        .video-upload-left {
            display: flex;
            width: 75%;

            .search-option {
                width: 95%;
                margin-right: 0px;

                .react-select-container {
                    .react-select__control {
                        min-height: 43px;
                    }
                }
            }

            .upload-buttons {
                display: none;
            }
        }

        .card-format {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            width: 25%;


            .button-1 {
                width: 43px;
                height: 43px;
                .icon-1 {

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
}

@media (max-width: 398px) {
    .video-upload-control {
        .video-upload-left {
            display: flex;
            width: 70%;
        }

        .card-format {
            width: 30%;
        }
    }
}