.home-page {
    position: relative;
    padding-top: 87px;

    &.active {
        padding-top: 173px;
    }

    .video-section-wrap {
        padding: 30px 15px;
        padding-top: 10px;

        .no-video-found{
            height: calc(100vh - 195px);
        }

        .video-section {
            padding: 25px 0px;
            padding-bottom: 10px;
            border-bottom: 1px solid #0000001A;

            .video-section-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                .video-section-top-left {
                    width: 65%;

                    h2 {
                        font-size: 24px;
                    }
                }

                .video-section-top-right {
                    a.btn-outline {
                        color: var(--primary-color);
                        border-radius: 36px;
                        border: 1px solid var(--primary-color);
                        padding: 7px 14px;
                        background: #ECF9F9;
                        font-size: 16px;
                        letter-spacing: 1px;
                        line-height: 19.49px;

                        &.disable {
                            opacity: 0.5;
                            pointer-events: none;
                            background-color: #e1fafa;
                        }

                        &:hover {
                            background-color: #e1fafa;
                        }
                    }
                }
            }

            .video-slider {
                position: relative;
                overflow: visible;

                .swiper-button-prev {
                    display: flex;
                    border-radius: 50%;
                    top: 35%;
                    left: -10px;
                    height: 40px;
                    width: 40px;
                    background-color: var(--white-bg);
                    z-index: 9999;
                    box-shadow: 4px 11px 58px 0px #00000012;

                    &:hover {
                        background-color: var(--primary-color);

                        &:after {
                            color: #FFF;
                        }
                    }

                    &:after {
                        font-size: 20px;
                        font-weight: 700;
                        color: #99A5B2;
                    }

                    &.swiper-button-disabled {
                        display: none;
                    }
                }

                .swiper-button-next {
                    display: flex;
                    border-radius: 50%;
                    top: 35%;
                    right: -10px;
                    height: 40px;
                    width: 40px;
                    background-color: var(--white-bg);
                    z-index: 9999;
                    box-shadow: 4px 11px 58px 0px #00000012;

                    &:hover {
                        background-color: var(--primary-color);

                        &:after {
                            color: #FFF;
                        }
                    }

                    &:after {
                        font-size: 20px;
                        font-weight: 700;
                        color: #99A5B2;
                    }

                    &.swiper-button-disabled {
                        display: none;
                    }
                }
            }

            &.video-list-section {
                .video-section-top {
                    .video-section-top-right {
                        margin-right: 90px;
                    }
                }

                .video-slider {
                    .swiper-button-prev {
                        height: 35px;
                        width: 35px;
                        top: -28px;
                        right: 40px;
                        left: auto;
                        background-color: #EEEEEE;
                        color: var(--border-color);

                        &:hover {
                            background: var(--primary-color);
                            color: var(--text-color);
                        }

                        &:after {
                            font-size: 18px;
                        }

                        &.swiper-button-disabled {
                            display: flex;
                        }
                    }

                    .swiper-button-next {
                        height: 35px;
                        width: 35px;
                        top: -28px;
                        right: 0px;
                        background-color: #EEEEEE;
                        color: var(--border-color);

                        &:hover {
                            background: var(--primary-color);
                            color: var(--text-color);
                        }

                        &:after {
                            font-size: 18px;
                        }

                        &.swiper-button-disabled {
                            display: flex;
                        }
                    }
                    &.list-slider{
                        .swiper-button-prev {
                            display: none;
                        }

                        .swiper-button-next {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .home-page {
        .video-section-wrap {
            .no-video-found{
                height: calc(100vh - 250px);
            }
            .video-section {
                .video-slider {
                    .swiper-button-prev {
                        display: none;
                    }

                    .swiper-button-next {
                        display: none;
                    }
                }

                &.video-list-section {
                    .video-section-top {
                        .video-section-top-right {
                            margin-right: 0px;
                        }
                    }

                    .video-slider {
                        .swiper-button-prev {
                            &.swiper-button-disabled {
                                display: none;
                            }
                        }

                        .swiper-button-next {
                            &.swiper-button-disabled {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .home-page {
        .video-section {
            .video-section-top {
                .video-section-top-left {

                    h2 {
                        font-size: 22px;
                    }
                }

                .video-section-top-right {
                    a.btn-outline {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}