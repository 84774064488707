.select-field {
    position: relative;
    z-index: 99;

    label {
        display: flex;
        justify-content: space-between;
        // margin-bottom: 5px;
        align-items: end;

        .label_name {
            font-weight: 500;
            font-size: 14px;
            line-height: 30px;
            color: var(--text-color);
        }

        .asterisk {
            color: red;
        }
    }

    .react-select-container {
        border-radius: 0px;

        &.react-select__control--is-not-focused {
            border: 1px solid #EB5757 !important;
            outline: 0 !important;
            border-radius: 0px;
            transition: 0.2s ease-in-out;

            .react-select__control {
                border: none;
            }
        }

        .react-select__control {
            min-height: 47px;
            border-radius: 4px;
            border-color: var(--border-color) !important;

            &.react-select__control--is-focused {
                border-color: var(--border-color) !important;
                outline: 0 !important;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
            }

            .react-select__value-container {
                padding: 5px 8px;
                .react-select__placeholder{
                    font-size: 16px;
                    color: var(--text-color);
                }
                .react-select__single-value{
                    font-size: 16px;
                    color: var(--text-color);
                }
            }

            .react-select__indicators {
                .react-select__dropdown-indicator {
                    svg {
                        color: var(--border-color);
                    }
                }
            }

            .react-select-menu {
                z-index: 999999;
            }
        }
    }


    .react-select__control .react-select__multi-value--is-multi {
        padding: 5px 0;
    }


    .react-select__control .react-select__multi-value__label {
        font-size: 14px;
    }


    .react-select__control .react-select__indicator-separator {
        display: none !important;
    }


    &.floating-label-input {
        position: relative;

        .floating-label {
            position: absolute;
            top: 23px;
            left: 10px;
            font-weight: 500;
            transform: translateY(-50%);
            background: white;
            padding: 0 5px;
            color: #999;
            transition: all 0.3s ease;
            pointer-events: none;

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0px;
            z-index: 1;
            .label_name{
                font-size: 16px;
            }

            &.focused {
                top: -2px;
                left: 10px;
                color: #333;
                padding: 0 5px;
                background: white;

                span {
                    font-size: 14px;
                }
            }
        }

        .react-select-container {
            border-radius: 0px;

            &.react-select__control--is-focused {
                .react-select-container {
                    border-color: #007bff;
                    box-shadow: 0 0 0 1px #007bff;
                }
            }
        }
    }

    .error-message {
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: #EB5757;
        bottom: 5px;

        svg {
            position: relative;
            top: 1px;
            margin-right: 2px;
        }
    }
}