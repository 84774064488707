.video-details-modal {
    .modal-content {
        border-radius: 4px;

        .modal-header {
            display: none;
        }

        .modal-body {
            padding: 0;
            position: relative;

            &:hover {
                .video-details-modal-heading-top {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .video-details-modal-heading-top {
                position: absolute;
                z-index: 99;
                right: 10px;
                top: 10px;
                visibility: hidden;
                opacity: 0;
                transition: all .3s;

                .video-details-modal-heading-top-right {
                    display: flex;
                    align-items: center;
                    gap: 13px;

                    .three-dot-icon {
                        position: relative;
                        transition: all .3s;
                        opacity: 1;
                        visibility: visible;
                        z-index: 50;

                        &.active {
                            opacity: 1;
                            visibility: visible;

                            .three-dot-button {
                                background-color: var(--white-bg);
                            }
                        }

                        button.three-dot-button {
                            background: none;
                            border: 0;
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            line-height: 1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            transition: all .3s;
                            background-color: var(--white-bg);
                            font-size: 15px;
                            // opacity: 0.33;
                            z-index: 9;

                            i {
                                opacity: 1;
                                font-size: 18px;
                                color: var(--text-color);
                                z-index: 99;
                            }

                            &:hover {
                                background-color: #f7f7f7;
                            }
                        }
                    }

                    .close-icon {
                        cursor: pointer;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        line-height: 1;
                        background-color: var(--white-bg);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        transition: all .3s;

                        svg {
                            width: 20px;
                            height: 20px;
                            font-size: 18;
                            color: var(--text-color);
                        }
                    }
                }
            }

            .video-details-modal-main {
                // padding: 20px;


                .video-details-modal-content-left-video-section {
                    .react-player {
                        border: 2px solid var(--white-bg);
                    }
                }

                .video-details-modal-content {
                    padding: 15px;

                    .video-details-title {
                        font-size: 20px;
                        color: var(--text-color);
                        margin-bottom: 10px;
                        font-weight: 500;
                        font-family: 'proxima_novaregular';
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--text-color);
                        line-height: 20.9px;
                        // padding: 5px 12px;
                        // border-radius: 4px;
                        // background-color: #EEEEEEEE;

                        span {
                            cursor: pointer;
                            color: var(--primary-color);

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .video-details-modal {
        .modal-content {
            .modal-body {
                .video-details-modal-heading-top {
                    .video-details-modal-heading-top-left {
                        .video-details-title {
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}