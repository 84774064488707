.simple_textarea_block {
    position: relative;

    label {
        display: flex;
        justify-content: space-between;

        .label_name {
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
            color: var(--text-color);
        }

        .asterisk {
            color: red;
        }
    }

    textarea {
        width: 100%;
        height: 130px;
        border: 1px solid var(--border-color);
        border-radius: 4px;
        background: var(--white-bg);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        font-size: 16px;
        padding: 10px 14px;
        font-weight: 500;
        line-height: 25px;
        color: var(--text-color);
        transition: all .3s ease;

        &::placeholder {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-color);
        }

        &:focus {
            border-color: var(--border-color);
        }
    }
    span.count-text{
        position: absolute;
        font-size: 14px;
        top: 100px;
        right: 20px;
        color: var(--text-color);
    }
    span.error-message {
        color: #eb5757;
        font-size: 13px;
        font-weight: 500;
    }

    &.floating-label-input {
        position: relative;
        margin-top: 5px;

        label {
            position: absolute;
            top: 15px;
            left: 10px;
            transform: translateY(-10%);
            background: white;
            padding: 0 5px;
            color: #999;
            transition: all 0.3s ease;
            pointer-events: none;
            font-size: 16px;
            z-index: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0px;

            .name {
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: var(--text-color);
            }

            .asterisk {
                color: red;
            }

            .link {
                a {
                    line-height: 14px;
                    text-decoration: none;
                    transition: all ease-in-out .35s;
                    color: var(--text-color);
                    font-weight: 600;
                    font-size: 14px;
                    cursor: pointer;

                    &:hover {
                        color: var(--primary-color);
                    }
                }
            }
        }

        textarea {
            &:focus {
                border-color: var(--border-color);
            }

            &:focus+label,
            &:not(:placeholder-shown)+label {
                top: -14px;
                left: 10px;
                color: #333;
                padding: 0 5px;
                background: white;

                span {
                    font-size: 14px;
                }
            }

            &.is-invalid {
                border-color: #eb5757;
    
                &:focus {
                    border-color: #eb5757;
                }
            }

            &:focus::placeholder {
                color: transparent;
            }
        }
    }
}

textarea.is-invalid {
    border: 1px solid #EB5757;
}