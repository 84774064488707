.auth-layout-wrapper {
    // align-items: center;
    // background-image: url('../../assets/image/IMG_5387.png');
    // background-position: bottom;
    // background-repeat: no-repeat;
    // background-size: cover;
    // max-height: 100%;
    // max-width: 100%;
    // display: flex;
    // justify-content: center;
    // min-height: 100vh;
    // width: 100vw;
    align-items: center;
    background-image: url('../../assets/image/auth-bg1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
}