.loader {
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 10px);
    z-index: 999;

    .wave {
        .dot {
            display: inline-block;
            width: 6px;
            height: 6px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            -ms-border-radius: 100%;
            -o-border-radius: 100%;
            border-radius: 100%;
            margin-right: 1px;
            background: var(--primary-color);
            -webkit-animation: wave 1.3s linear infinite;
            -moz-animation: wave 1.3s linear infinite;
            -ms-animation: wave 1.3s linear infinite;
            -o-animation: wave 1.3s linear infinite;
            animation: wave 1.3s linear infinite;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            margin-right: 4px;

            &:last-of-type {
                margin-right: 0;
            }

            &:nth-child(2) {
                -webkit-animation-delay: -1.1s;
                -moz-animation-delay: -1.1s;
                -ms-animation-delay: -1.1s;
                -o-animation-delay: -1.1s;
                animation-delay: -1.1s;
            }

            &::nth-child(3) {
                -webkit-animation-delay: -.9s;
                -moz-animation-delay: -.9s;
                -ms-animation-delay: -.9s;
                -o-animation-delay: -.9s;
                animation-delay: -.9s;
            }
        }

        @keyframes wave {

            0%,
            100%,
            60% {
                transform: initial
            }

            30% {
                transform: translateY(-5px)
            }
        }
    }

}

.overlayed {
    background-color: #f4f2ee;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    opacity: .8;
    border-radius: 8px;
    /* in FireFox */
    filter: alpha(opacity=50);
    /* in IE */
}