.add-module-modal {
    .modal-content {
        max-width: 520px;

        .modal-body {
            padding: 25px;

            .add-module-btn {
                display: flex;
                gap: 10px;
                justify-content: flex-end;
                margin-top: 20px;
            }
        }
    }
}

@media (max-width: 576px) {
    .add-module-modal {
        .modal-content {
            .modal-body{
                padding: 20px;
            }
        }
    }
}