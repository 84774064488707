.share-video-modal {
    .modal-content {
        .modal-body {
            form {
                .configurable-fields {
                    position: relative;
                    padding-top: 15px;
                    margin-top: 8px;
                    border-top: 1px solid #0000001A;

                    &.fast {
                        padding-top: 0px;
                        margin-top: 0px;
                        border-top: none;
                    }

                    .mobile-number {
                        position: relative;
                        .mobile-code {
                            position: absolute;
                            top: 16px;
                            left: 28px;
                            z-index: 1;
                            font-size: 16px;
                            font-weight: 500;
                            color: var(--text-color);
                        }

                        .single-input {
                            input {
                                padding: 10px 16px 10px 30px;
                            }
                        }
                    }

                    .remove-btn {
                        width: 100%;
                        margin-bottom: 10px;

                        span {
                            background-color: transparent;
                            justify-content: flex-start;
                            color: #FF5252;
                            cursor: pointer;
                            padding-top: 0px;
                        }
                    }
                }

                .video-share-btn {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 0px;
                    padding-top: 15px;
                    border-top: 1px solid #0000001A;

                    .add-field-btn {
                        .button-1 {
                            border: 1px solid #949FAC;
                            padding: 8px 15px;
                            color: var(--primary-color);
                            background: var(--btn-bg);
                            border-color: var(--primary-color);

                            &:hover {
                                background-color: #e1fafa;
                            }
                        }
                    }

                    .video-share-btn-right {
                        display: flex;
                        justify-content: flex-end;
                        gap: 10px;

                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .share-video-modal {
        .modal-content {
            .modal-body {
                form {

                    .video-share-btn {

                        .add-field-btn {
                            .button-1 {
                                padding: 6px 13px;

                                .btn-text {
                                    display: none;
                                }

                                .icon-1 {
                                    margin: 0px;

                                    svg {}
                                }
                            }
                        }

                        .video-share-btn-right {
                            .button-1 {
                                padding: 6px 10px;
                            }
                        }

                    }
                }
            }
        }
    }
}