.header {
    position: fixed;
    display: flex;
    align-items: center;
    height: 65px;
    box-shadow: 4px 11px 58px 0px #00000012;
    border-bottom: 1px solid #E0E4E8;
    background: #ffffff;
    width: 100%;
    z-index: 999;
    top: 0px;

    .header-left {
        display: flex;
        align-items: center;

        span.menu-icon {
            padding-right: 15px;

            svg {
                width: 23px;
                height: 23px;
                cursor: pointer;
                color: #001F3E;
            }
        }

        .header-logo {
            display: inline-block;
        }
    }

    .menu {
        ul {
            list-style: none;
            margin: 0px;
            padding: 0;

            li {
                display: inline-block;

                .mobile-number {
                    display: none;

                    a {
                        color: var(--primary-color);
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    span {
                        margin-right: 5px;
                        color: var(--primary-color);
                    }
                }

                .logout-btn {
                    color: #FF5252;
                    background-color: #FFEAEA;

                    &:hover {
                        color: #fd4b4b;
                        background-color: #fcc8c8;
                    }
                }
            }
        }
    }
}

.mobile-sticky-menu {
    border-top: 1px solid #EDEDED;
    height: 65px;
    padding: 10px 64px;
    position: fixed;
    background: #fff;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    z-index: 999;

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;

        li {
            display: inline-block;
            text-align: center;

            a {
                display: inline-block;
                text-align: center;
                font-size: 13px;
                cursor: pointer;
                font-weight: 500;
                line-height: 20px;
                color: #000000;
                position: relative;
                transition: all .4s ease-in-out;

                svg {
                    display: block;
                    margin: 0 auto;
                    width: 22px;
                    height: 22px;
                    color: #000000;

                    &.active {
                        display: none;
                    }
                }

                span {
                    display: block;
                    color: #000000;
                }

                &.active {
                    span {
                        color: var(--primary-color);

                        svg {
                            color: var(--primary-color);
                        }
                    }
                }

                &:hover {
                    span {
                        color: var(--primary-color);

                        svg {
                            color: var(--primary-color);
                        }
                    }
                }
            }

        }
    }
}



@media (max-width: 991px) {
    .mobile-sticky-menu {
        display: inline-flex;
    }

    .notification-box-area {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 99999;
        height: 100vh;
        background: #fff;
        overflow-y: auto;

        h4.title {
            max-width: 450px;
            margin: 0 auto;
            padding-top: 10px;
            font-size: 16px;
            text-align: center;
        }

        .back {
            position: absolute;
            left: 20px;
            font-size: 24px;
            top: 0px;
            cursor: pointer;
        }

        .notification-list-area {
            max-width: 450px;
            margin: 0 auto;
            width: 100%;
            max-height: 100%;
            border-radius: 0;
        }
    }
}

@media (max-width: 576px) {
    .header {
        .menu {
            ul {
                li {
                    .logout-btn {
                        padding: 4px 11px;
                        font-size: 14px;

                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }

    .mobile-sticky-menu {
        padding: 10px 30px;
    }
}