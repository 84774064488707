.multiple-checked-overlay {
    top: -259px;
    position: absolute;
    background: #050505;
    height: 243px;
    width: 100%;
    z-index: 88;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    display: none;

    &.active {
        opacity: 0;
        visibility: visible;
    }
}

.multiple-checked-container {
    // position: absolute;
    position: fixed;
    width: calc(100% - 230px);
    top: -150px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    margin: auto;
    z-index: 99;
    z-index: 99;
    padding: 15px;
    background-color: var(--white-bg);

    &.active {
        opacity: 1;
        visibility: visible;
        display: block;
        top: 65px;
    }

    .multiple-checked {
        margin: 0px;
        border-radius: 117px;
        background: var(--primary-color);
        padding: 10px 15px;

        .multiple-checked-left {
            display: flex;
            align-items: center;
            .cross-icon{
                margin-right: 10px;
                cursor: pointer;
                svg{
                    width: 17px;
                    height: 17px;
                    color: var(--white-bg);
                }
            }
            .select-checkbox {
                color: var(--white-bg);
                font-size: 15px;
            }
        }

        .multiple-checked-middle {
            .select-checkbox {
                margin-right: 10px;

                .button {
                    background: var(--primary-color);
                    border: 1px solid #6c3f0e;
                }
            }
        }

        .multiple-checked-right {
            float: right;
            .share {
                border-radius: 36px;
                padding: 6px 14px;
                background: var(--white-bg);
                color: var(--primary-color);
                display: flex;
                align-items: center;
                svg{
                    width: 24px;
                    height: 24px;
                }
                &:hover{
                    background: #f7f7f7
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .multiple-checked-container {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .multiple-checked-container {
        padding: 15px 10px;
        .multiple-checked{
            padding: 10px 15px;
            .multiple-checked-left{
                .cross-icon{
                    svg{
                        width: 15px;
                        height: 15px;
                    }
                }
            }
            .multiple-checked-middle{
                .select-checkbox{
                    font-size: 13px;
                }
            }
            .multiple-checked-right{
                .share{
                    padding: 5px 10px;
                    font-size: 14px;
                    svg{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}
