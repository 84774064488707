.button-1 {
    background: var(--primary-color);
    color: var(--white-bg);
    border: 1px solid var(--border-color);
    padding: 8px 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-style: normal;
    transition: all .3s;
    font-weight: 500;
    line-height: 24px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    letter-spacing: 1px;

    &:after {
        content: "";
        clear: both;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: -100%;
        background: var(--headding-color);
        opacity: 0.1;
        z-index: -1;
        transition: all .3s ease;
    }

    &:hover {
        &:after {
            left: 0px;
        }
    }

    &.button-2 {
        color: var(--body-color);
        border: 1px solid var(--primary-color);
        background: transparent;

        &:hover {
            background: var(--primary-color);
            color: var(--white-bg);
        }
    }

    &.cancel-btn {
        border: 1px solid var(--border-color);
        background-color: transparent;
        color: #282828;
        min-width: 81px;

        &:hover {
            background: #f7f7f7;
        }
    }

    &.submit-btn {
        background-color: var(--primary-color);
        color: var(--white-bg);
        min-width: 81px;

        &:hover {
            background: #35b19e;
        }
        &:disabled {
            opacity: .5;
            cursor: no-drop;
            background-color: var(--primary-color) !important;
            color: var(--white-bg) !important;
            min-width: 81px;
        }
    }

    &.back {
        border: 1px solid var(--primary-color);
        padding: 11px 40px;
        color: #696E76;
        background: transparent;

        &:hover {
            color: #fff;
            background: var(--primary-color);
        }
    }

    span.icon-1 {
        margin-right: 6px;
        opacity: 1;
        line-height: 0px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    span.loading-icon{
        margin-right: 6px;
        opacity: 1;
        line-height: 0px;
    }

    span.icon-2 {
        margin-left: 6px;
        opacity: 1;
        line-height: 0px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &:disabled {
        opacity: .4;
        cursor: no-drop;
        color: var(--text-color) !important;
        // background-color: #EEEEEE !important;
    }
}

@media (max-width: 991px) {
    .button-1 {
        font-size: 15px;
    }
}