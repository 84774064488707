.video-upload-modal {
  .modal-content {
    .modal-body {
      padding: 25px;
      position: relative;

      .btn-outline {
        border: 1px solid var(--primary-color);
        height: 47px;
        color: var(--primary-color);
        background: transparent;
        width: 100%;

        &:hover {
          background: #f7f7f7;
        }
      }

      .thumbnail-img {
        margin-bottom: 15px;

        .lable {
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          color: var(--text-color);
          margin-bottom: 10px;
        }

        span.error-message {
          color: #eb5757;
          font-size: 13px;
          font-weight: 500;
        }

        .asterisk {
          color: red;
        }

        .thumbnail-img-loader {
          width: 100%;
          border-radius: 8px;
          border: 2px dashed var(--border-color);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 200px;

          svg {
            width: 40px;
            height: 40px;
          }
        }

        .images {
          width: 200px;
          margin-top: 10px;
          //border-radius: 8px;
        }

        .upload-image {
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          // padding: 50px 30px;
          height: 200px;
          // border: 2px dashed var(--border-color);
          cursor: pointer;
          background-color: #EEEEEE;
          overflow: hidden;
          
          &.no-image{
            .img-upload-main{
                transform: scale(1);
                opacity: 1;
                visibility: visible;
            }
          }
          &:hover {
            .img-upload-main{
                transform: scale(1);
                opacity: 1;
                visibility: visible;
            }
          }

          .file_upload {
            opacity: 0.0;

            /* IE 8 */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

            /* IE 5-7 */
            filter: alpha(opacity=0);

            /* Netscape or and older firefox browsers */
            -moz-opacity: 0.0;

            /* older Safari browsers */
            -khtml-opacity: 0.0;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }

          .file-icon {
            margin: 0 auto 7px;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #E7E7E7;

            svg {
              width: 44px;
              height: 44px;
            }
          }

          .drag-text {
            text-align: center;
            color: var(--border-color);
            font-size: 16px;
            line-height: 24px;
            font-style: normal;
            font-weight: 500;
          }

          span {
            padding: 0 10px;
            color: var(--border-color);
          }
        }
      }

      .upload-video-btn {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
      }
    }
  }
}

.img-upload-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: 0.2s;
  transform: scale(0.5);
  opacity: 0;
  visibility: hidden;
  background-color: #ccc;
  z-index: 9;
}

.img-preview-block{
    width: 100%;
    
    img{
      width: 100%;
    }
  }

@media (max-width:991px) {
  .video-upload-modal {
    height: 100vh;
    margin: 0px !important;
    padding: 0px !important;

    .modal-content {
      width: 100vw;
      z-index: 999999;
      // height: 100vh;
      border-radius: 0px;
      overflow-y: hidden;
      border: none;
    }

  }
}

@media (max-width: 767px) {
  .video-upload-modal {
    .modal-content {
      .modal-body {
        padding: 20px;

        .btn-outline {
          border: 1px solid var(--primary-color);
          height: 47px;
          padding: 0px 5px;
          color: var(--primary-color);
          background: transparent;
          width: 100%;

          &:hover {
            background: #f7f7f7;
          }
        }
      }
    }
  }

}

@media (max-width: 576px) {
  .video-upload-modal {
    .modal-content {
      .modal-body {
        .btn-outline {
          padding: 0;

          .btn-text {
            display: none;
          }

          .icon-1 {
            margin: 0;
          }
        }
      }
    }
  }

}