.meeting-alert-body {
    text-align: center;
    padding: 20px;
    color: sienna;
}
.modal{
    padding-left: 0px !important;
}
.modal.fade .modal-dialog{
    margin-bottom: 100px !important;
}
.modal-content {
    border-radius: 0px;
    margin-bottom: 100px;
    z-index: 999999;
    .w-100.modal-title.h4 {
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        padding: 0px 10px;
        color: var(--text-color);
        text-transform: capitalize;
        word-break: break-all;
    }
}
.modal-header{
    .btn-close {
        display: none;
    }
    svg{
        color: #66798B;
    }
}

.upload-videos-modal{
    top: 40px;
    position: relative;
    &.modal-lg{
        max-width: 850px;
        box-sizing: border-box;
        padding: 0px 15px;
    }
}
.not-title-header-modal{
    position: relative;
    &.modal-lg {
        max-width: 850px;
        box-sizing: border-box;
        padding: 0px 20px;
    }
    .modal-content {
        margin-top: 35px;
    }
    .modal-header {
        padding: 0;
        height: 0;
        border: none;
        .modal-close-b {
            cursor: pointer;
            z-index: 9;
            line-height: 1;
            font-size: 27px;
            color: #fff;
            position: absolute;
            top: -12px;
            right: -12px;
        }
    }
}

.modal-close-b {
    cursor: pointer;
}

@media (max-width: 576px) {
    .upload-videos-modal{
        &.modal-lg{
            padding: 0px;
        }
    }
    .modal-content .w-100.modal-title.h4 {
        font-size: 16px;
        line-height: 24px;
    }
}