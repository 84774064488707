.sidebar-overlay {
    width: 0%;
    height: 100%;
    position: fixed;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    cursor: crosshair;
    background: #232323;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    top: 65px;

    &.active {
        width: 100%;
        opacity: 0.7;
        visibility: visible;
    }
}

.sidebar {
    // display: block;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    visibility: visible;
    opacity: 1;
    padding: 12px;
    width: 100%;
    max-width: 230px;
    height: calc(100vh - 65px);
    float: left;
    overflow-y: auto;
    position: fixed;
    top: 65px;
    left: 0px;
    z-index: 999;
    background: #ffffff;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    box-shadow: 4px 11px 58px 0px #00000012;
    border-right: 1px solid #E0E4E8;


    .single-sidebar-menu {
        padding-top: 12px;
        padding-bottom: 12px;

        .sidebar-title {
            font-size: 16px;
            color: var(--headding-color);
            padding-left: 10px;
            padding-bottom: 15px;
        }

        nav {
            ul {
                list-style: none;
                margin: 0px;
                padding: 0;

                .accordion {
                    --bs-accordion-active-bg: transparent;
                    --bs-accordion-bg: var(--headding-color2);
                    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem transparent;
                    --bs-accordion-border-width: 0px;
                    --bs-accordion-btn-active-icon: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');

                    .accordion-item {
                        --bs-accordion-bg: #ebf1f1;

                        .accordion-header {
                            .accordion-button {
                                padding: 15px 15px;
                                border-bottom: 1px solid #dddcdc82;

                                .service {
                                    color: var(--text-color2);
                                    padding: 0px;
                                    border-bottom: 0px;
                                    display: flex;
                                    gap: 10px;
                                    font-size: 14px;
                                }

                                &.collapsed {
                                    border-bottom: none;
                                }
                            }
                        }

                        .accordion-collapse {
                            ul {
                                li {
                                    a.sidebar-button {
                                        padding-left: 34px;
                                    }
                                }
                            }
                        }
                    }
                }

                li {
                    width: 100%;
                    display: inline-block;

                    .sidebar-button {
                        padding: 10px 16px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 600;
                        color: var(--primary-color);
                        gap: 10px;
                        border-radius: 4px;

                        svg {
                            // color: var(--text-color2);
                            transition: 0.4s;
                            width: 22px;
                            height: 22px;
                        }

                        

                    }

                    .sidebar-button.active {
                        font-weight: 600;
                        color: var(--primary-color);
                        background-color: #ECF7F5;

                        &:hover {
                            // font-weight: 500;
                            background-color: #ECF7F5;
                            color: var(--primary-color);
                            svg{
                                color: var(--primary-color);
                            }
                        }

                        svg {
                            color: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .sidebar {
        opacity: 0;
        margin-left: -245px;
        visibility: hidden;

        &.active {
            margin-left: 0px;
            visibility: visible;
            opacity: 1;
        }
    }
}