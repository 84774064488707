.single-input {
    position: relative;

    .input-icon {
        &.disable {
            display: none;
        }

        svg {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 10px;
            left: 10px;
        }
    }


    .input-icon img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 10px;
        left: 10px;
    }

    label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;

        .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 30px;
            color: var(--text-color);
        }

        .asterisk {
            color: red;
        }

        .link {
            a {
                line-height: 14px;
                text-decoration: none;
                transition: all ease-in-out .35s;
                color: var(--text-color);
                font-weight: 600;
                font-size: 14px;
                cursor: pointer;

                &:hover {
                    color: var(--primary-color);
                }
            }
        }
    }

    input {
        width: 100%;
        background: var(--white-bg);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-radius: 4px;
        border: 1px solid var(--border-color);
        font-size: 16px;
        padding: 10px 16px 10px 16px;
        font-weight: 500;
        line-height: 25px;
        color: var(--text-color);
        transition: all .3s ease;

        &.is-invalid {
            border-color: #eb5757;

            &:focus {
                border-color: #eb5757;
            }
        }

        &::placeholder {
            font-size: 15px;
            font-weight: 400;
            color: var(--body-color);
        }

        &:focus {
            // border-color: var(--text-color);
            border: 1px solid var(--border-color);
        }
    }

    span.error-message {
        color: #eb5757;
        font-size: 13px;
        font-weight: 500;
    }

    span.password-visiablity svg {
        position: absolute;
        top: 43px;
        right: 12px;
        cursor: pointer;
        color: var(--body-color);
        width: 22px;
        height: 22px;
        line-height: 1;
        z-index: 999;
    }

    &.floating-label-input{
        margin-top: 5px;
        label {
            position: absolute;
            top: 23px;
            left: 10px;
            transform: translateY(-50%);
            background: white;
            padding: 0 5px;
            color: #999;
            transition: all 0.3s ease;
            pointer-events: none;
            z-index: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0px;
    
            .name {
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: var(--text-color);
            }
    
            .asterisk {
                color: red;
            }
    
            .link {
                a {
                    line-height: 14px;
                    text-decoration: none;
                    transition: all ease-in-out .35s;
                    color: var(--text-color);
                    font-weight: 600;
                    font-size: 14px;
                    cursor: pointer;
    
                    &:hover {
                        color: var(--primary-color);
                    }
                }
            }
        }
        input{
            &:focus {
                border: 1px solid var(--border-color);
            }
    
            &:focus+label,
            &:not(:placeholder-shown)+label {
                top: -2px;
                left: 10px;
                color: #333;
                padding: 0 5px;
                background: white;
                span{
                    font-size: 14px;
                }
            }
            &.is-invalid {
                border-color: #eb5757;
    
                &:focus {
                    border-color: #eb5757;
                }
            }
    
            &:focus::placeholder {
                color: transparent;
            }
        }
    }
}