.video-card {
    width: 100%;
    transition: all 0.3s ease;
    position: relative;
    margin-bottom: 20px;

    &.pending-video-card {

        // opacity: 0.6;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 4px;
            width: 100%;
            transition: all 0.3s;
            opacity: 0.6;
            background: #ffffff;
            background-size: 100% 100%;
            z-index: 1;
        }

        .video-card-thumbnail {
            pointer-events: none;

            .card-inner {
                position: relative;

                span {
                    position: absolute;
                    bottom: 7px;
                    right: 7px;
                    background-color: black;
                    padding: 2px 6px;
                    border-radius: 4px;
                    font-size: 13px;
                    color: #fff;
                    display: none;
                }

                .conversation-status {
                    left: 7px;
                    right: inherit;
                    display: block;
                    z-index: 9;
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 4px;
                width: 100%;
                transition: all 0.3s;
                opacity: 0.3;
                background: #000;
                background-size: 100% 100%;
                z-index: 1;
            }

        }

        .video-card-header {
            pointer-events: none;
        }

        .checkbox {
            opacity: 0;
            visibility: hidden;
            display: none;
        }

        .three-dot-icon {
            opacity: 0;
            visibility: hidden;
            display: none;
        }

        &:hover {
            .video-card-thumbnail {
                &::after {
                    opacity: 0;
                }

            }

            .checkbox {
                opacity: 0;
                visibility: hidden;
                display: none;
            }

            .three-dot-icon {
                opacity: 0;
                visibility: hidden;
                display: none;
            }

        }
    }

    .video-card-thumbnail {
        position: relative;
        cursor: pointer;

        .card-inner {
            position: relative;

            .conversation-status {
                left: 7px;
                right: inherit;
            }

            span {
                position: absolute;
                bottom: 7px;
                right: 7px;
                background-color: black;
                padding: 2px 6px;
                border-radius: 4px;
                font-size: 13px;
                color: #fff;
            }
        }

        &.checked-video-opacity {
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 4px;
                width: 100%;
                transition: all 0.3s;
                opacity: 0.3;
                background: #000;
                background-size: 100% 100%;
                z-index: 1;
            }
        }

        &.checked-video {
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 4px;
                width: 100%;
                transition: all 0.3s;
                opacity: 0.5;
                background: #000;
                background-size: 100% 100%;
                z-index: 1;
            }
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: 1px solid #DBDBDB;
            min-height: 150px;
        }

        &::after {
            position: absolute;
            content: "\f04b";
            font-family: 'FontAwesome';
            font-size: 35px;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate(0%, -100%);
            color: var(--white-bg);
            opacity: 0;
            transition: all 0.5s;
            text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2509803922);
            z-index: 9;
        }
    }

    .video-card-header {
        h2 {
            font-size: 19px;
            color: var(--text-color);
            font-weight: 400;
            margin: 10px 0;
            transition: all .3s;
            font-family: 'proxima_novaregular';
            cursor: pointer;

            &:hover {
                color: var(--primary-color);
            }
        }

        p {
            margin-top: 15px;
            display: none;
        }
    }

    .checkbox {
        position: absolute;
        left: 10px;
        top: 10px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        z-index: 50;

        &.active {
            opacity: 1;
            visibility: visible;

            .round {
                position: relative;

                label {
                    // background-color: #fff;
                    border: 2px solid var(--white-bg);
                    border-radius: 10%;
                    cursor: pointer;
                    width: 23px;
                    height: 23px;
                    left: 0;
                    position: absolute;
                    top: 0;

                    &:after {
                        border: 2px solid var(--white-bg);
                        border-top: none;
                        border-right: none;
                        content: "";
                        height: 5px;
                        left: 5px;
                        opacity: 0;
                        position: absolute;
                        top: 6px;
                        transform: rotate(-45deg);
                        width: 11px;
                    }
                }

                input[type="checkbox"] {
                    visibility: hidden;
                }

                input[type="checkbox"]:checked+label {
                    // background-color: var(--primary-color);
                    border-color: var(--white-bg);
                }

                input[type="checkbox"]:checked+label:after {
                    opacity: 1;
                }
            }
        }

        .round {
            position: relative;

            label {
                // background-color: #fff;
                border: 2px solid var(--white-bg);
                border-radius: 10%;
                cursor: pointer;
                width: 23px;
                height: 23px;
                left: 0;
                position: absolute;
                top: 0;
                box-shadow: 0px 4px 4px 0px #00000040;

                &:after {
                    color: var(--white-bg);
                    border: 2px solid #fff;
                    border-top: none;
                    border-right: none;
                    content: "";
                    height: 5px;
                    left: 5px;
                    opacity: 0;
                    position: absolute;
                    top: 6px;
                    transform: rotate(-45deg);
                    width: 11px;
                }
            }

            input[type="checkbox"] {
                visibility: hidden;
            }

            input[type="checkbox"]:checked+label {
                // background-color: var(--primary-color);
                border-color: var(--white-bg);
            }

            // input[type="checkbox"]:checked+label {
            //     background-color: #2E70E8;
            //     border-color: #2E70E8;
            // }

            input[type="checkbox"]:checked+label:after {
                opacity: 1;
            }
        }
    }

    .three-dot-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        z-index: 50;

        &.active {
            opacity: 1;
            visibility: visible;

            .three-dot-button {
                background-color: var(--white-bg);
            }
        }

        button.three-dot-button {
            background: none;
            border: 0;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: all .3s;
            background-color: var(--white-bg);
            font-size: 15px;
            // opacity: 0.33;
            z-index: 9;

            i {
                opacity: 1;
                font-size: 18px;
                color: var(--border-color);
                z-index: 99;
            }

            &:hover {
                background-color: #f7f7f7;
            }
        }
    }


    &:hover {
        .checkbox {
            opacity: 1;
            visibility: visible;
        }

        .three-dot-icon {
            opacity: 1;
            visibility: visible;
        }

        .video-card-thumbnail {
            &::after {
                opacity: 1;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 4px;
                width: 100%;
                transition: all 0.3s;
                opacity: 0.3;
                background: #000;
                background-size: 100% 100%;
                z-index: 1;
            }

            &.checked-video-opacity {
                &:before {
                    opacity: 0.3;
                }
            }

            &.checked-video {
                &:before {
                    opacity: 0.5;
                }
            }
        }
    }

    &.list-view-video-card {
        // display: flex;

        .three-dot-icon {
            top: 5px;
        }

        .video-card-thumbnail {
            // min-width: auto;
            // height: 130px;
            display: inline-block;
            width: 40%;
            float: left;

            img {
                min-height: auto;
            }

            &::after {
                font-size: 30px;
            }

            &.checked-video {
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    min-width: auto;
                    height: 100%;
                    border-radius: 4px;
                    width: 100%;
                    transition: all 0.3s;
                    opacity: 0.5;
                    background: #000;
                    background-size: 100% 100%;
                    z-index: 1;
                }
            }
        }

        .video-card-header {
            display: inline-block;
            width: 60%;
            float: right;
            padding-left: 15px;

            h2 {
                width: 77%;
            }

            p {
                display: -webkit-box;
                font-size: 14px;
                line-height: 20.9px;
                color: var(--text-color);
                width: 95%;
            }
        }

        .three-dot-icon {
            visibility: visible;
            opacity: 1;

            .three-dot-button {
                background-color: #f7f7f7;
            }
        }
    }

}

@media (min-width: 1441px) {
    .video-card {
        &.list-view-video-card {
            .video-card-thumbnail {
                p {
                    min-width: 90%;
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .video-card {
        .video-card-header {
            h2 {
                font-size: 20px;
            }
        }

        &.list-view-video-card {
            .video-card-header {
                h2 {
                    font-size: 18px;
                    margin: 5px 0px;
                    width: 75%;
                }
            }
        }

    }
}

@media (max-width: 991px) {
    .video-card {
        .checkbox {
            top: 6px;

            &.active {
                .round {
                    label {
                        width: 20px;
                        height: 20px;

                        &:after {
                            left: 3px;
                            top: 4px;
                        }
                    }
                }
            }

            .round {
                label {
                    width: 20px;
                    height: 20px;

                    &:after {
                        left: 3px;
                        top: 4px;
                    }
                }
            }
        }

        .three-dot-icon {
            top: 6px;
            opacity: 1;
            visibility: visible;

            button.three-dot-button {
                width: 30px;
                height: 30px;

            }
        }
    }
}

@media (max-width: 768px) {
    .video-card {
        .video-card-header {
            h2 {
                font-size: 18px;
                margin: 10px 0;
            }

            p {
                font-size: 13px;
            }
        }

    }
}

@media (max-width: 576px) {
    .video-card {
        .video-card-header {
            h2 {
                font-size: 16px;
            }
        }

        &.list-view-video-card {
            .three-dot-icon {
                top: -2px;
            }

            .video-card-header {
                p {
                    margin-top: 8px;
                }

            }
        }
    }
}

@media (max-width: 450px) {
    .video-card {
        &.list-view-video-card {
            .video-card-thumbnail {
                .card-inner {
                    span {
                        padding: 1px 4px;
                        font-size: 11px;
                    }
                }

            }

            .video-card-header {
                padding-left: 10px;

                h2 {
                    font-size: 16px;
                    width: 70%;
                    margin: 5px 0;
                }

                p {
                    font-size: 13px;
                    line-height: 17px;
                }

            }
        }
    }
}

@media (max-width: 380px) {
    .video-card {
        &.list-view-video-card {
            .video-card-header {
                padding-left: 8px;

                h2 {
                    font-size: 14px;
                }
            }
        }
    }
}